<template>
	<div class="password-form">
		<div class="password-form__header">
			<v-btn class="password-form__header-button" icon color="#575E60" @click="frompage ? $router.back() : $router.push({ name: 'Home' })">
				<v-icon class="password-form__header-button-icon">far fa-arrow-left</v-icon>
			</v-btn>
			<h2 class="password-form__header-title">{{ $t('login.forgotten') }}</h2>

			<div></div>
		</div>
		<p class="password_form__subtitle">{{ $t('login.recuperar') }}</p>

		<form @submit.prevent="resetPassword">
			<div class="password-form__inputs">
				<v-text-field
					class="password-form__input"
					:label="$t('login.email')"
					v-model="email"
					type="email"
					:color="primaryColor"
					required
				></v-text-field>
			</div>
			<div class="password-form__bottom">
				<v-btn class="password-form__button" color="#2498ff" type="submit" depressed>
					{{ $t('login.submit') }}
				</v-btn>
			</div>
		</form>
	</div>
</template>

<script>
import { passwordReset } from '@/api/sessions.js';

export default {
	name: 'ResetPassword',
	props: { frompage: Boolean },
	data() {
		return {
			email: null
		};
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	mounted() {},
	methods: {
		async resetPassword() {
			if (this.email) {
				const data = await passwordReset(this.$store.state.eventInfo.evgrurlname, this.email);
				console.log(data);
				data.responseType === 'success'
					? this.$puiNotify.success(this.$t('success.success_password'), this.$t('success.success'))
					: this.$puiNotify.error(this.$t('error.error_password'), this.$t('error.error'));
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.password-form {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
	width: 392px;
	margin-top: 101px;
	margin-bottom: 101px;
}

.password-form__header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 32px;
		/* identical to box height, or 123% */

		letter-spacing: -0.27px;
	}
}

.password_form__subtitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	/* or 131% */

	/* Neutral / Dark grey */

	color: #575e60;
	padding-top: 22px;
	margin-bottom: 0px !important;
}

.password-form__inputs {
	padding-top: 0.5rem;
}

.password-form__input {
	width: 20.5rem;
}

.password-form__bottom {
	display: flex;
	flex-direction: column;
	padding-top: 7px;
}

.password-form__button {
	color: #fff;
}
</style>
